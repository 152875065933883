import {

  mdiDesktopMac,

} from '@mdi/js'

export default [
  'General',
  [
   
    {
      to: '/dashboard',
      icon: mdiDesktopMac,
      label: 'Panel de Control'
    }
  ],
 
]
